<template>
  <!--  :style="creationData.length > 0 ? '' : 'border-bottom: none'"-->
  <div class="scientificPublication" style="padding-top: 4px; ">
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 1145px; margin-right: 0">
        <label>Название публикации <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.articlePubName"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 260px">
        <label>Тип публикации <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articlePubType"
          :items="articlePubTypeArray"
          @change="getSprPubCategory"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 260px">
        <label>Категория публикации <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articlePubCategory"
          :items="articlePubCategoryArrayComputed"
          @change="journalCategory"
        ></v-autocomplete>
      </div>
      <div
        class="inputDiv" style="width: 575px; margin-right: 0"
        v-if="creationData.articlePubCategory === 3 || creationData.articlePubCategory === 4"
      >
        <label>Выбор журнала <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articleJournal.id"
          :items="articleJournalArray"
          :search-input.sync="search"
          no-data-text="Начните вводить название"
          return-object
        ></v-autocomplete>
      </div>
    </div>
    <div
      class="scientificPublication__row"
      v-if="creationData.articlePubCategory === 5 || creationData.articlePubCategory === 6
      || creationData.articlePubCategory === 7 || creationData.articlePubCategory === 8"
    >
      <div class="inputDiv" style="width: 1111px; margin-right: 0">
        <label>{{ creationData.articlePubCategory === 5 ? 'Название журнала' : 'Название конференции' }} <span
          style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.articleJournalName"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 550px">
        <label>Автор(ы) <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.articleAuthors"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv" style="width: 575px; margin-right: 0">
        <label>URL</label>
        <v-text-field
          v-model="creationData.articleUrl"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <v-dialog
      v-model="invalidData"
      width="500px"
    >
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Требуется заполнить все обязательные поля' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="wrongFileTypeDialog"
      width="665px"
    >
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Загруженный формат скана не соответствует расширению .pdf' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteConfirmDialog"
      width="375"
    >
      <v-card>
        <v-card-title>Подтверждение удаления строки</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 400px">
        <label>Номер издания</label>
        <v-text-field
          v-model="creationData.articleEditionNum"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv" style="width: 130px">
        <label>Год публикации <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articlePubYear"
          :items="articlePubYearArray"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 120px">
        <label>Страницы</label>
        <v-text-field
          v-model="creationData.articlePages"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv" style="width: 135px">
        <label>Печатн. листов ¹ <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.articleNumPages"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv" style="width: 280px; margin-right: 0">
        <label>DOI</label>
        <v-text-field
          v-model="creationData.articleDoi"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <div class="fileDiv">
      <v-file-input
        prepend-icon="mdi-folder-open-outline" v-model="creationData.scanIdSysScan"
        accept="application/pdf" :style="creationData.sysScanFileUuid ? 'display: none' : ''"
        placeholder="Выберите файл"
        @change="wrongFileTypeDialogFunc($event)"
      ></v-file-input>
      <div class="file__info">
        <p class="file__text" v-if="!creationData.sysScanFileUuid">Скан документа</p>
        <p
          class="file__text file__text_link" v-else
          @click="getFile(creationData.sysScanFileUuid, creationData.sysScanFileNameOrig, creationData.sysScanFileExt)">
          Скан документа
        </p>
        <p class="file__status" v-if="creationData.scanIdSysScan">Загружен</p>
        <p class="file__status file__status_empty" v-else>Не сохранено</p>
      </div>
      <img
        v-if="creationData.sysScanFileUuid" src="@/assets/icons/close-red-round.svg"
        alt="delete" style="margin-left: 20px;" class="fileDiv__delete" @click="deleteFile(creationData.id)">
      <p class="file__calc">
        ¹ Количество печатных листов рассчитывается следующим образом: 1 печатный лист = 40 000 печатных знаков с
        учетом
        знаков препинания, цифр и пробелов. Либо 1 печатный лист = 16 страниц формата А4.
      </p>
    </div>
    <div class="btnDiv" style="padding: 22px 0 0 0; border-top: none">
      <button
        class="usual-btn" :disabled="!blockEditingInProcess" @click="updateInfo"
      >
        Сохранить
      </button>
      <button class="usual-btn usual-btn_grey" @click="getAllBlock">Отмена</button>
      <p class="block__hint"><span style="color: red">*</span> Все поля, помеченные звездочкой, обязательны для
        заполнения</p>
      <button
        v-if="block1Data.id"
        class="usual-btn usual-btn_delete" style="margin-left: auto" @click="deleteConfirmFunc"
      >
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Block1Creation",
  data() {
    return {
      loading: false,
      search: null,
      invalidData: false,
      blockEditingInProcess: true,
      deleteConfirmDialog: false,
      wrongFileTypeDialog: false,
      wrongFileType: false,
      creationData: {
        id: 0,
        articlePubType: null,
        articlePubCategory: null,
        articleJournal: {id: null},
        articleJournalSearch: '',
        articleJournalName: "", // название журнала, если не из справочника
        articlePubName: "",
        articleAuthors: "",
        articlePubYear: null, //2021 2022 2023
        articleEditionNum: "",
        articlePages: "", // с ... по ... страницы
        articleNumPages: null, // число страниц
        articleDoi: "",
        articleUrl: "",
        scanIdSysScan: null,
      },
      articlePubYearArray: [
        {text: '2022 год', value: 2022},
        {text: '2023 год', value: 2023},
        // {text: '2024 год', value: 2024},
      ],
      articlePubTypeArray: [],
      articlePubCategoryArray: [],
      articleJournalArray: [],
      rules: {
        required: value => !!value || 'Обязательное поле!',
      }
    }
  },
  props: {
    rowID: {
      type: String,
      default: ''
    },
    specID: {
      type: Number,
      default: 0
    },
    block1Data: {
      type: Object,
      default: function () {
        return {}
      },
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),

    articlePubCategoryArrayComputed() {
      let array = this.articlePubCategoryArray;
      if (this.creationData.articlePubType === 1) {
        array = array.filter(el => el.value === 3 || el.value === 4 || el.value === 5)
      } else {
        array = array.filter(el => el.value === 6 || el.value === 7 || el.value === 8)
      }

      if (this.specID === 6) {
        array = array.filter(el => el.value !== 5 && el.value !== 8)
      }

      return array
    },

    fieldsValidation() {
      if (this.creationData.articlePubName && this.creationData.articlePubType && this.creationData.articlePubCategory
        && (this.creationData.articleJournal?.id || this.creationData.articleJournalName) && this.creationData.articleAuthors
        && this.creationData.articlePubYear && this.creationData.articleNumPages) {
        return true
      } else {
        return false
      }
    },

    articleJournalforPost() {
      if (this.creationData.articleJournalName) {
        return {"id": null}
      } else if (this.creationData.articleJournal.id.value) {
        return {"id": this.creationData.articleJournal.id.value}
      } else {
        return {"id": this.creationData.articleJournal.id}
      }
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    search(val) {
      if (val.length > 2) {
        fetch(`/api/2024/spr/eLibrary/search?stringSearch=${val}&catPub=${this.creationData.articlePubCategory}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
          .then(res => res.json())
          .then(res => {
            this.articleJournalArray = [];
            for (let i = 0; i < res.length; i++) {
              this.articleJournalArray.push({
                text: res[i].name,
                value: res[i].id
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
  },

  created() {
    this.createdFunc();
  },

  methods: {
    async createdFunc() {
      await this.getSprPubType();

      if (this.block1Data.id) { // проверка на создание/редактирование
        await this.getSprPubCategory();
        this.creationData = this.block1Data;
        if (this.creationData.articleJournal) {
          await this.getSprJournal(this.creationData.articleJournal.name);
        }
      }
    },
  
    wrongFileTypeDialogFunc(arg) {
      if (arg.type !== 'application/pdf') {
        this.wrongFileTypeDialog = true;
        this.wrongFileType = true;
      } else {
        this.wrongFileType = false;
      }
    },

    async getSprPubType() {
      try {
        const res = await axios.get(`/api/2024/spr/textVal/get?block=1&position=1`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articlePubTypeArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprPubType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprPubCategory() {
      this.articlePubCategoryArray = [];
      this.creationData.articlePubCategory = null;
      try {
        const res = await axios.get(`/api/2024/spr/textVal/get?block=1&position=2`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articlePubCategoryArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprPubCategory успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    journalCategory() {
      this.creationData.articleJournalName = ''
      if (this.creationData.articlePubCategory === 3 || this.creationData.articlePubCategory === 4) {
        this.creationData.articleJournal = {id: null}
      }
    },

    async getSprJournal(search) {
      this.articleJournalArray = [];
      try {
        const res = await axios.get(`/api/2024/spr/eLibrary/search?stringSearch=${search}&catPub=${this.creationData.articlePubCategory}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articleJournalArray.push({
              text: res.data[i].name,
              value: res.data[i].id
            })
          }
          console.log('getSprJournal успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async updateInfo() {
      if (this.fieldsValidation && !this.wrongFileType) {
        let url = `/api/2024/main/block1/add`;
        if (this.creationData.id !== 0) {
          url = `/api/2024/main/block1/update`
        }
        const formData = new FormData();
        if (this.creationData.scanIdSysScan && typeof this.creationData.scanIdSysScan !== "number") {
          formData.append('file', this.creationData.scanIdSysScan);
        }
        formData.append('data', new Blob([JSON.stringify({
          "id": this.creationData.id !== 0 ? this.creationData.id : '',
          "mainId": this.rowID,
          "articlePubName": this.creationData.articlePubName,
          "articlePubType": this.creationData.articlePubType,
          "articlePubCategory": this.creationData.articlePubCategory,
          "articleJournal": this.articleJournalforPost, // случай выбора из списка
          "articleJournalName": this.creationData.articleJournalName ? this.creationData.articleJournalName : '', // случай написания собственного варианта
          "articleAuthors": this.creationData.articleAuthors,
          "articleUrl": this.creationData.articleUrl,
          "articleEditionNum": this.creationData.articleEditionNum,
          "articlePubYear": this.creationData.articlePubYear,
          "articlePages": this.creationData.articlePages,
          "articleNumPages": String(this.creationData.articleNumPages).replace(/,/g, "."),
          "articleDoi": this.creationData.articleDoi,
        })], {type: "application/json"}));
        try {
          const res = await axios.post(url, formData,
            {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
          if (res.status === 200 && res.data) {
            this.$emit("updateData"); // вызов getAll в корневом файле
            this.blockEditingInProcess = false;
            this.loading = false;
            console.log('updateInfo успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else if (this.wrongFileType) {
        this.wrongFileTypeDialog = true
      } else {
        this.invalidData = true
      }
    },

    getAllBlock() {
      this.$emit("updateData");
    },

    deleteConfirmFunc() {
      this.deleteConfirmDialog = true;
    },

    async deleteRow() {
      try {
        const res = await axios.delete(`/api/2024/main/block1/delete?rowId=${this.block1Data.id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          console.log('deleteRow успех');
          this.$emit("updateData");
        }
      } catch (e) {
        console.log(e);
      }
    },

    async deleteFile(ID) {
      this.loading = true;
      let uri = `/api/2024/main/block1/scan/delete?rowId=${ID}`;
      try {
        const res = await axios.delete(uri, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.creationData.scanIdSysScan = null;
          this.creationData.sysScanFileUuid = null;
          this.loading = false;
          console.log('deleteFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },


    async getFile(id, name, ext) {
      let uri = `/api/2024/main/blocks/scan/download?sysScanFileUuid=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext)
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },
  }
}
</script>

<style scoped>

</style>
