<template>
  <div>
    <div class="scientificPublication" v-if="!blockEditingInProcess">
      <div
        :style="
          !isExpert && !isManager
            ? 'position: absolute; top: 18px; right: 14px'
            : 'position: absolute; top: 10px; right: 14px'
        ">
        <!--        v-if="konvertConvert && (isExpert || isManager)"-->
        <button
          class="usual-btn"
          style="padding: 5px 34px"
          @click="editRowExpert"
          v-if="konvertConvert && isManager">
          Балл
        </button>
        <button
          class="usual-btn usual-btn_disabled"
          v-else-if="applicantBlocked && (!isExpert || isManager)">
          Изменить
        </button>
        <button
          class="usual-btn"
          @click="editRow"
          v-else-if="!applicantBlocked && (!isExpert || isManager)">
          Изменить
        </button>
        <button class="usual-btn" @click="cancelEditRow" v-else-if="!isExpert || isManager">
          Отменить
        </button>
        <v-dialog v-model="deleteConfirmDialog" width="375">
          <v-card>
            <v-card-title>Подтверждение удаления строки</v-card-title>
            <v-card-actions style="justify-content: center">
              <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
              <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="curatorBlockEditingInProcess" width="500">
          <v-card>
            <v-card-title>Введите балл</v-card-title>
            <v-card-actions style="justify-content: center">
              <div class="expertBall">
                <div class="inputDiv">
                  <label>Балл</label>
                  <v-text-field v-model="value.ballBallExpert"></v-text-field>
                </div>
                <p class="bold" style="margin: 15px auto 0 5px">Комментарий</p>
                <textarea class="textarea" v-model="value.ballCommentExpert"></textarea>
                <v-btn
                  class="dialog-btn"
                  style="margin-top: 25px; margin-bottom: 15px"
                  @click="expertBallUpdate(value.id, value.ballBallExpert, value.ballCommentExpert)"
                >Сохранить
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div
          v-if="konvertConvert && (isExpert || isManager)"
          class="applicantsSelectionJsonOrgScore">
          <p
            style="margin-top: 3px"
            v-if="value.ballBallMethod + value.ballBallExpert > 0 && (!isExpert || isManager)">
            {{
              value.ballBallMethod
            }}<span style="font-size: 14px; line-height: 16px">методика</span>
          </p>
          <p style="margin-top: 3px" v-if="value.ballBallMethod + value.ballBallExpert > 0">
            {{
              value.ballBallExpert
            }}<span style="font-size: 14px; line-height: 16px">комиссия</span>
            <span
              v-if="value.ballCommentExpert && value.ballCommentExpert !== 'null'"
              :data-tooltip="`${value.ballCommentExpert}`"
              class="expertBall-info"></span>
          </p>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; max-width: 1000px">
        <p class="type" style="margin-right: 4px">
          <span style="margin-right: 3px" class="order">{{ order + 1 }}</span>
          {{ itemType ? itemType : '' }}
          <span class="category">{{ itemCategory ? itemCategory : '' }}</span>
          <span style="margin-left: 15px" class="category">DOI: {{ value.articleDoi }}</span>
        </p>
        <p
          class="order"
          style="margin: 0 22px 4px 0"
          v-if="(isExpert || isManager) & (value.articleJournal !== null)">
          <span v-if="value.articleJournal.wosCc === 1">
            WoS: Да (кв: {{ value.articleJournal.jcrQuartile }});&nbsp;
          </span>
          <span v-if="value.articleJournal.scopus === 1">
            Scp: Да (кв: {{ value.articleJournal.sjrQuartile }});&nbsp;
          </span>
          <span v-if="value.articleJournal.rincCore === 1">ЯдРИНЦ: Да;&nbsp;</span>
          <span v-if="value.articleJournal.rinc === 1">РИНЦ: Да;&nbsp;</span>
          <span v-if="value.articleJournal.vak === 1">ВАК: Да;&nbsp;</span>
        </p>
      </div>
      <p class="name" style="margin-top: 7px" v-html="value.articlePubName"></p>
      <p class="authors" style="margin-top: 5px" v-html="value.articleAuthors"></p>
      <div class="textInfoDiv">
        <p class="text">{{ journalType }}</p>
        <div
          class="scan"
          v-if="value.sysScanFileUuid"
          @mouseout="hover.scan = false"
          @mouseover="hover.scan = true">
          <img
            v-if="hover.scan"
            src="@/assets/icons/stp_scan_orange.svg"
            class="scan__img"
            alt="scan"/>
          <img v-else src="@/assets/icons/stp_scan_blue.svg" class="scan__img" alt="scan"/>
          <p
            class="scan__text"
            @click="
              getFile(value.sysScanFileUuid, value.sysScanFileNameOrig, value.sysScanFileExt)
            ">
            Скан документа
          </p>
        </div>
        <div
          class="url"
          v-if="value.articleUrl"
          @mouseout="hover.url = false"
          @mouseover="hover.url = true">
          <img
            v-if="hover.url"
            src="@/assets/icons/stp_url_orange.svg"
            class="url__img"
            alt="url"/>
          <img v-else src="@/assets/icons/stp_url_blue.svg" class="url__img" alt="url"/>
          <a :href="linkCheck(value.articleUrl)" class="url__text" target="_blank">Ссылка</a>
        </div>
      </div>
    </div>
    <div v-if="blockEditingInProcess">
      <Block1Creation
        :rowID="rowID"
        :specID="specID"
        :block1Data="value"
        @updateData="updateData"/>
    </div>
  </div>
</template>

<script>
import Block1Creation from '@/2024/components/applicants/applicantForm/mainBlocks/blockCreation/Block1Creation';
import axios from 'axios';
import {mapGetters} from 'vuex';

export default {
  name: 'Block1Item',
  components: {Block1Creation},
  data() {
    return {
      blockEditingInProcess: false,
      curatorBlockEditingInProcess: false,
      // expertBall: null,
      // expertComment: null,
      deleteConfirmDialog: false,
      hover: {
        scan: false,
        url: false,
      },
    };
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
    articlePubTypeArray: {
      type: Array,
      default: function () {
        return [];
      },
    },
    articlePubCategoryArray: {
      type: Array,
      default: function () {
        return [];
      },
    },
    rowID: {
      type: String,
      default: '',
    },
    specID: {
      type: Number,
      default: 0,
    },
    order: {
      type: Number,
      default: 0,
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    },
    konvertConvert: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isExpert: 'isExpert',
      // isCurator: 'isCurator',
      isManager: 'isManager',
    }),

    itemType() {
      return `${
        this.articlePubTypeArray.filter((el) => el.value === this.value.articlePubType)[0]?.text
      } /`;
      //DOI: ${this.value.articleDoi}
    },

    itemCategory() {
      return `${
        this.articlePubCategoryArray.filter((el) => el.value === this.value.articlePubCategory)[0]
          ?.text
      }`;
    },

    journalType() {
      return `${
        this.value.articleJournal?.name
          ? this.value.articleJournal?.name
          : this.value.articleJournalName
      },
      ${this.value.articleEditionNum}, ${this.value.articlePubYear}. Страницы ${
        this.value.articlePages
      }
      (${this.value.articleNumPages}  печ. лист)`;
    },
  },
  methods: {
    editRow() {
      this.blockEditingInProcess = true;
    },

    editRowExpert() {
      this.curatorBlockEditingInProcess = true;
    },

    cancelEditRow() {
      this.blockEditingInProcess = false;
      this.$emit('updateData');
    },

    updateData() {
      // вызов getAll в корневом файле
      this.$emit('updateData');
    },

    deleteConfirmFunc() {
      this.deleteConfirmDialog = true;
    },

    async deleteRow() {
      try {
        const res = await axios.delete(`/api/2024/main/block1/delete?rowId=${this.value.id}`, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          console.log('deleteRow успех');
          this.$emit('updateData');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async expertBallUpdate(rowId, ball, comment) {
      let url = `/api/2024/main/expertBall/block1`;
      const formData = new FormData();
      formData.append('rowId', rowId);
      formData.append('ball', ball);
      formData.append('comment', comment);
      try {
        const res = await axios.post(url, formData, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.value.ballBallExpert = ball;
          this.value.ballCommentExpert = comment;
          this.$emit('update:value.ballBallExpert', ball);
          this.$emit('update:value.ballCommentExpert', comment);
          // this.$emit("updateData"); // вызов getAll в корневом файле
          this.curatorBlockEditingInProcess = false;
          console.log('expertBallUpdate успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getFile(id, name, ext) {
      let uri = `/api/2024/main/blocks/scan/download?sysScanFileUuid=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext);
    },

    linkCheck(link) {
      if (link.slice(0, 4) === 'http') {
        return link;
      } else {
        return `http://${link}`;
      }
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },
  },
};
</script>

<style scoped></style>
