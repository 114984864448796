import { render, staticRenderFns } from "./Block6Creation.vue?vue&type=template&id=04d7ae0f&scoped=true&"
import script from "./Block6Creation.vue?vue&type=script&lang=js&"
export * from "./Block6Creation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d7ae0f",
  null
  
)

export default component.exports