<template>
  <!--      :style="creationData.length > 0 ? '' : 'border-bottom: none'"-->
  <div class="scientificPublication" style="padding-top: 4px">
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 625px">
        <label>Название мероприятия <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.eventEventName"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv" style="width: 235px">
        <label>Тип мероприятия <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.eventEventType"
          :items="eventTypeArray"></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 235px; margin-right: 0">
        <label>Статус <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.eventStatus"
          :items="filteredStatusArray"></v-autocomplete>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 200px">
        <label>Форма проведения <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.eventEventForm"
          :items="eventForms"></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 405px">
        <label>Место проведения <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.eventEventPlace"
          :disabled="!blockEditingInProcess || creationData.eventEventForm !== 'Очная'"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv" style="width: 235px">
        <label>Дата начала мероприятия <span style="color: red">*</span></label>
<!--        :max-date="maxDate"-->
        <vue-ctk-date-time-picker
          id="DateTimePicker"
          v-model="creationData.eventEventDateStart"
          :no-button-now="true"
          only-date
          format="YYYY-MM-DD"
          formatted="LL"
          color="#00599B"
          button-color="#00599B"
          min-date="2022-01-01"
          max-date="2023-12-31"
          label=""
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          :disabled="!blockEditingInProcess"
        />
      </div>
      <div class="inputDiv" style="width: 235px; margin-right: 0">
        <label>Дата окончания мероприятия <span style="color: red">*</span></label>
<!--        :max-date="maxDate"-->
        <vue-ctk-date-time-picker
          id="DateTimePicker"
          v-model="creationData.eventEventDateEnd"
          :no-button-now="true"
          only-date
          format="YYYY-MM-DD"
          formatted="LL"
          color="#00599B"
          button-color="#00599B"
          min-date="2022-01-01"
          max-date="2023-12-31"
          label=""
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          :disabled="!blockEditingInProcess"
        />
      </div>
    </div>
    <v-dialog v-model="invalidData" width="500px">
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Требуется заполнить все обязательные поля' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="wrongFileTypeDialog" width="665px">
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Загруженный формат скана не соответствует расширению .pdf' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteConfirmDialog" width="375">
      <v-card>
        <v-card-title>Подтверждение удаления строки</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 1200px; margin-right: 0">
        <label
        >Документ, подтверждающий победу (достижения за 2 место и более низкого уровня не
          учитываются) <span style="color: red">*</span></label
        >
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.eventDocProof"
          :items="docsArray"></v-autocomplete>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="fileDiv" style="margin-top: 34px; width: 180px">
        <v-file-input
          prepend-icon="mdi-folder-open-outline"
          v-model="creationData.scanIdSysScan"
          accept="application/pdf"
          :style="creationData.sysScanFileUuid ? 'display: none' : ''"
          placeholder="Выберите файл"
          @change="wrongFileTypeDialogFunc($event)"></v-file-input>
        <div class="file__info">
          <p class="file__text" style="min-width: 105px" v-if="!creationData.sysScanFileUuid">
            Скан документа
          </p>
          <p
            class="file__text file__text_link"
            style="min-width: 105px"
            v-else
            @click="
              getFile(
                creationData.sysScanFileUuid,
                creationData.sysScanFileNameOrig,
                creationData.sysScanFileExt,
              )
            ">
            Скан документа
          </p>
          <p class="file__status" v-if="creationData.scanIdSysScan">Загружен</p>
          <p class="file__status file__status_empty" v-else>Не сохранено</p>
        </div>
        <img
          v-if="creationData.sysScanFileUuid"
          src="@/assets/icons/close-red-round.svg"
          alt="delete"
          class="fileDiv__delete"
          @click="deleteFile(creationData.id)"/>
      </div>
    </div>
    <div class="btnDiv" style="padding: 22px 0 0 0; border-top: none">
      <button class="usual-btn" :disabled="!blockEditingInProcess" @click="updateInfo">
        Сохранить
      </button>
      <button class="usual-btn usual-btn_grey" @click="getAllBlock">Отмена</button>
      <p class="block__hint">
        <span style="color: red">*</span> Все поля, помеченные звездочкой, обязательны для
        заполнения
      </p>
      <button
        v-if="block5Data.id"
        class="usual-btn usual-btn_delete"
        style="margin-left: auto"
        @click="deleteConfirmFunc">
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters} from 'vuex';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  name: 'Block5Creation',
  components: {VueCtkDateTimePicker},
  data() {
    return {
      loading: false,
      invalidData: false,
      blockEditingInProcess: true,
      deleteConfirmDialog: false,
      wrongFileTypeDialog: false,
      wrongFileType: false,
      creationData: {
        id: 0,
        eventEventType: null,
        eventStatus: null,
        eventEventName: '',
        eventEventPlace: ' ',
        eventDocProof: '',
        eventEventDateStart: '',
        eventEventDateEnd: '',
        eventEventForm: '',
        scanIdSysScan: null,
      },
      eventTypeArray: [],
      statusArray: [],
      eventForms: [
        {text: 'Очная', value: 'Очная'},
        {text: 'Онлайн/интернет', value: 'Онлайн/интернет'},
      ],
      docsArray: [],
      rules: {
        required: (value) => !!value || 'Обязательное поле!',
      },
    };
  },
  props: {
    rowID: {
      type: String,
      default: '',
    },
    block5Data: {
      type: Object,
      default: function () {
        return {};
      },
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
    }),

    filteredStatusArray() {
      return this.statusArray
    },

    fieldsValidation() {
      if (
        this.creationData.eventEventName &&
        this.creationData.eventEventType &&
        this.creationData.eventStatus &&
        (this.creationData.eventEventPlace || this.creationData.eventEventForm === 'Онлайн/интернет') &&
        this.creationData.eventDocProof &&
        this.creationData.eventEventForm &&
        this.creationData.eventEventDateStart &&
        this.creationData.eventEventDateEnd
      ) {
        return true;
      } else {
        return false;
      }
    },

    maxDate() {
      let x = new Date(Date.now()).toLocaleDateString().replace(/[.]/g, '-');
      let years = x.slice(-4);
      let days = x.slice(0, 2);
      let months = x.slice(2, -4);
      return `${years}${months}${days}`;
    },
  },
  created() {
    this.createdFunc();
  },
  methods: {
    async createdFunc() {
      await this.getSprEventType();
      await this.getSprStatus();
      await this.getDocs();

      if (this.block5Data.id) {
        // проверка на создание/редактирование
        this.creationData = this.block5Data;
      }
    },

    wrongFileTypeDialogFunc(arg) {
      if (arg.type !== 'application/pdf') {
        this.wrongFileTypeDialog = true;
        this.wrongFileType = true;
      } else {
        this.wrongFileType = false;
      }
    },

    async getSprEventType() {
      try {
        const res = await axios.get(`/api/2024/spr/textVal/get?block=5&position=1`, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.eventTypeArray.push({
              text: res.data[i].value,
              value: res.data[i].id,
            });
          }
          console.log('getSprEventType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprStatus() {
      try {
        const res = await axios.get(`/api/2024/spr/textVal/get?block=5&position=2`, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.statusArray.push({
              text: res.data[i].value,
              value: res.data[i].id,
            });
          }
          console.log('getSprStatus успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getDocs() {
      try {
        const res = await axios.get(`/api/2024/spr/textVal/get?block=5&position=3`, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.docsArray.push({
              text: res.data[i].value,
              value: res.data[i].id,
            });
          }
          console.log('getDocs успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async updateInfo() {
      if (this.fieldsValidation && !this.wrongFileType) {
        let url = `/api/2024/main/block5/add`;
        if (this.creationData.id !== 0) {
          url = `/api/2024/main/block5/update`;
        }
        const formData = new FormData();
        if (
          this.creationData.scanIdSysScan &&
          typeof this.creationData.scanIdSysScan !== 'number'
        ) {
          formData.append('file', this.creationData.scanIdSysScan);
        }
        formData.append(
          'data',
          new Blob(
            [
              JSON.stringify({
                id: this.creationData.id !== 0 ? this.creationData.id : '',
                mainId: this.rowID,
                eventEventType: this.creationData.eventEventType,
                eventStatus: this.creationData.eventStatus,
                eventEventName: this.creationData.eventEventName,
                eventEventPlace: this.creationData.eventEventPlace,
                eventEventForm: this.creationData.eventEventForm,
                eventDocProof: this.creationData.eventDocProof,
                eventEventDateStart: this.creationData.eventEventDateStart,
                eventEventDateEnd: this.creationData.eventEventDateEnd,
              }),
            ],
            {
              type: 'application/json',
            },
          ),
        );
        try {
          const res = await axios.post(url, formData, {
            headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
          });
          if (res.status === 200 && res.data) {
            this.$emit('updateData'); // вызов getAll в корневом файле
            this.blockEditingInProcess = false;
            this.loading = false;
            console.log('updateInfo успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else if (this.wrongFileType) {
        this.wrongFileTypeDialog = true;
      } else {
        this.invalidData = true;
      }
    },

    getAllBlock() {
      this.$emit('updateData');
    },

    deleteConfirmFunc() {
      this.deleteConfirmDialog = true;
    },

    async deleteRow() {
      try {
        const res = await axios.delete(
          `/api/2024/main/block5/delete?rowId=${this.block5Data.id}`,
          {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}},
        );
        if (res.status === 200 && res.data) {
          console.log('deleteRow успех');
          this.$emit('updateData');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async deleteFile(ID) {
      this.loading = true;
      let uri = `/api/2024/main/block5/scan/delete?rowId=${ID}`;
      try {
        const res = await axios.delete(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.creationData.scanIdSysScan = null;
          this.creationData.sysScanFileUuid = null;
          this.loading = false;
          console.log('deleteFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async getFile(id, name, ext) {
      let uri = `/api/2024/main/blocks/scan/download?sysScanFileUuid=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext);
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },
  }
}
</script>

<style scoped>

</style>
